header {

  .header__top {
    padding: 25px 0;
    background-color: $header_top-background;

    a {
      color: $theme-text;

      h2 {
        font-size: 36px;
        line-height: 58px;
      }

      &:hover, &:focus {
        color: $theme-text;
        text-decoration: none;
      }
    }

    .modiam-logo {
      height: 58px;
      width: auto;
      max-width: 100%;
    }
  }

  .header__bottom {
    position: static;
    width: auto;
    background-color: $header_bottom-background;

    .main-nav {
      margin: 0;
      padding: 0;
      list-style: none;

      > li {
        margin-right: 45px;
        opacity: .7;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;

        > a {
          display: block;

          padding: 14px 0;
          text-decoration: none;
          color: $header_bottom-text;

          .material-icons {
            display: inline-block;
            margin-bottom: 0;
            font-size: 36px;
          }

          span {
            display: inline-block;
            font-size: 17px;
            line-height: 20px;
            vertical-align: middle;
            white-space: pre-wrap;
          }
        }

        &.active, &:hover, &:focus {
          opacity: 1;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

}