/*
 Import plugins
  */
@import 'bootstrap/scss/bootstrap.scss';

/*
 Import others
  */
@import "variables";
@import 'header';



main {
  padding: 30px 0;
}

/* Formulier onderwijs product tabel */
.clear-disabled-style {
  width: 100%;
  border: none;
  background: transparent;
  height: auto;
}

/* FIXES */
button:focus,
a:focus, a:active,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: none !important;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}