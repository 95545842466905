// Main Theme colors
$theme-orange: #E85120;
$theme-bluedark: #003B80;
$theme-bluelight: #D8D9E9;
$theme-text: #212529;

// Header
$header_top-background: white;
$header_top-bottomborder: $theme-bluedark;
$header_middle-background: $theme-orange;
$header_middle-text: white;
$header_bottom-background: $theme-orange;
$header_bottom-text: white;
